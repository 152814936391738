export const CENTER = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const Colors = {
  MAIN: "#e0b761",
  SUBMAIN: "#232222",
  PRIMARY: "#027bff",
  SECONDARY: "#6c757d",
  SUCCESS: "#86fa47",
  ERROR: "#ed1a23",
  WARNING: "#fef200",
  INFO: "#14a2b8",
};

export const FormatCurrency = (props: {
  amount: number;
  showCurrency?: boolean;
}) => {
  let result = "";

  result = props.amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");

  if (props.showCurrency) {
    result = `Rp ${result}`;
  }

  return result;
};

export const SocialCreds = {
  REDIRECT_URL: "https://drx-mini-auth.firebaseapp.com/__/auth/handler",
  FB_APP_ID: "1519366155443273",
  GG_APP_ID: "",
  AMAZON_APP_ID: "",
  INSTAGRAM_APP_ID: "",
  INSTAGRAM_APP_SECRET: "",
  MICROSOFT_APP_ID: "",
  LINKEDIN_APP_SECRET: "",
  LINKEDIN_APP_ID: "",
  GITHUB_APP_ID: "",
  GITHUB_APP_SECRET: "",
  PINTEREST_APP_ID: "",
  PINTEREST_APP_SECRET: "",
  TWITTER_APP_ID: "29359902",
  TWITTER_V2_APP_KEY: "lOoEqcuKVPWEW8XI8jHSK70iW",
  TWITTER_V2_APP_SECRET: "K68Cic2lIRuotLVvHjR59RPlY78gHj7SxEhmFVsd47TLfw91sU",
  APPLE_ID: "",
  TIKTOK_CLIENT_KEY: "",
};

// FB KEYS = "313fa15ccd93ed745a7e660bdb9fe4fd"
// TWITTER BEARER = "AAAAAAAAAAAAAAAAAAAAAB7%2FvwEAAAAAnUsH%2F9HLP6SUBlQdndNzubScrjs%3DQ63CBbbbEuGXL3yVkLkapvlP2GhtHLUKb2zd4x7iQ0qkwyx0zO"
// TWITTER TOKEN = "1837417511626985473-Lu5Pu30gwCcz3o36pT44KNL3uSJ8ho"
// TWIITER SECRET = "ShU0OLRuNUECbTjPLuemCr1BJizbCJDr7hawJ17i0BVDx"

// TWITTER KEY2 = "Rk40SUs4RHJhRjMzbnBINHRIdlU6MTpjaQ"
// TWIITER SECRET2 = "6qAvxOq6z6bQmAWf86VoWJZ1SbTLdOtty9bJMzTERby94dHtQX"

export const FirebaseConfig = {
  apiKey: "AIzaSyDUY7bcfLBCKx95xrSPpnn0hIItqsxep1k",
  authDomain: "drx-mini-auth.firebaseapp.com",
  projectId: "drx-mini-auth",
  storageBucket: "drx-mini-auth.appspot.com",
  messagingSenderId: "194998075325",
  appId: "1:194998075325:web:202b2db3ea31a79dbf1cd4",
  measurementId: "G-HY4PK9NHE6"
};
