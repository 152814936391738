import { initializeApp } from "firebase/app";
import {
  getAuth,
  TwitterAuthProvider,
  signInWithRedirect,
  getRedirectResult,
  FacebookAuthProvider,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDUY7bcfLBCKx95xrSPpnn0hIItqsxep1k",
  authDomain: "drx-mini-auth.firebaseapp.com",
  projectId: "drx-mini-auth",
  storageBucket: "drx-mini-auth.appspot.com",
  messagingSenderId: "194998075325",
  appId: "1:194998075325:web:202b2db3ea31a79dbf1cd4",
  measurementId: "G-HY4PK9NHE6",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export {
  auth,
  signInWithRedirect,
  getRedirectResult,
  TwitterAuthProvider,
  FacebookAuthProvider,
};
