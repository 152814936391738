import { Avatar, Box, CircularProgress, Icon, Stack, Typography } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router';
import { Assets } from '../../assets';
import { BottomNavigation } from '../../components/navigation/bottomBar';
import RouterTransitions from '../../routes/routerTransition';
import { CENTER, Colors, FormatCurrency } from '../../utils/utilities';
import { motion, TapInfo } from "framer-motion";
import './styles.css'
import { useSelector } from 'react-redux';
import { RootState } from '../../stores/rootReducer';
import { HTTPPointGet, HTTPPointStore } from '../../servers/points';
import { stagger, useAnimate, animate } from "framer-motion";
import './styles.css'
import CountUp from 'react-countup';

const randomNumberBetween = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
};

type AnimationSequence = Parameters<typeof animate>[0];

const HomePage = () => {
    const navigate = useNavigate()
    const ProfileRedux = useSelector((state: RootState) => state.profile_data.data)
    const [TapCount, setTapCount] = React.useState(0)
    const [TotalCoins, setTotalCoins] = React.useState(0)
    const [init, setInit] = React.useState(false)
    const particleClicksRef: any = React.useRef(null);
    const [scope, animate] = useAnimate();
    // const buttonRef: any = React.useRef(null);
    // const isScrolling: any = React.useRef(false);
    // const startY: any = React.useRef(0);

    const onClaim = async () => {
        if (TapCount > 0) {
            const clickSound = new Audio(`${Assets.sound.claim}`);
            clickSound.play();
            const sparkles = Array.from({ length: 20 });
            const sparklesAnimation: AnimationSequence = sparkles.map((_, index) => [
                `.sparkle-${index}`,
                {
                    x: randomNumberBetween(-100, 120),
                    y: randomNumberBetween(-100, 120),
                    scale: randomNumberBetween(1.5, 2.5),
                    opacity: 1,
                },
                { duration: 0.4, at: "<" }
            ]);

            const sparklesFadeOut: AnimationSequence = sparkles.map((_, index) => [
                `.sparkle-${index}`,
                { opacity: 0, scale: 0 },
                { duration: 0.3, at: "<" },
            ]);

            const sparklesReset: AnimationSequence = sparkles.map((_, index) => [
                `.sparkle-${index}`,
                { x: 0, y: 0 },
                { duration: 0.000001 },
            ]);

            setTotalCoins(TotalCoins + TapCount)
            setTapCount(0)

            animate([
                ...sparklesReset,
                [".letter", { y: -32 }, { duration: 0.2, delay: stagger(0.05) }],
                [".claim-btn", { scale: 0.8 }, { duration: 0.1, at: "<" }],
                [".claim-btn", { scale: 1 }, { duration: 0.1 }],
                ...sparklesAnimation,
                [".letter", { y: 0 }, { duration: 0.000001 }],
                ...sparklesFadeOut,
            ]);

            await HTTPPointStore({ point: TapCount, user_id: ProfileRedux.id })
        }
    };

    const createParticle = (x: any, y: any) => {
        const container = document.createElement("div");
        container.setAttribute("class", "drx-particle");
        container.style.position = "absolute";
        container.style.left = `${x}px`;
        container.style.top = `${y}px`;

        const text = document.createElement("span");
        text.textContent = `+1`;
        text.style.color = '#fff';
        text.style.marginLeft = '10px';
        text.style.fontSize = '40px';
        text.style.fontWeight = '700px';

        container.appendChild(text);

        const particleClicks = particleClicksRef.current;
        particleClicks.appendChild(container);

        setTimeout(() => {
            particleClicks.removeChild(container);
        }, 3000);
    };

    const handleClick = React.useCallback((e: any) => {
        const clickSound = new Audio(`${Assets.sound.click}`);
        clickSound.play();
        setTapCount(TapCount + 1)
        const rect = e.target.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        createParticle(x, y);
    }, [TapCount]);

    const GetCoins = async () => {
        try {
            const result = await HTTPPointGet({ user_id: ProfileRedux.id })
            if (result.data.data.point) {
                setTotalCoins(parseInt(result.data.data.point))
            } else {
                setTotalCoins(0)
            }
        } catch (error) {
            console.log(error)
        }
    }

    React.useEffect(() => {
        const Initial = () => {
            GetCoins()
        }
        Initial()
    }, [init])

    // const handleTouch = (event: any) => {
    //     const touchCount = e.touches.length;
    //     setTapCount(TapCount + touchCount)
    //     const rect = e.target.getBoundingClientRect();

    //     for (let i = 0; i < touchCount; i++) {
    //         let x = (e.targetTouches[i].clientX) ? e.targetTouches[i].clientX - rect.left : 0;
    //         let y = (e.targetTouches[i].clientY) ? e.targetTouches[i].clientY - rect.top : 0;
    //         createParticle(x, y);
    //     }
    // };

    return (
        <div style={{ ...CENTER, width: '100%' }}>
            <div style={{ width: '100%', maxWidth: '768px', backgroundColor: '#000', ...CENTER, position: 'relative' }}>
                <Stack direction={'column'} sx={{ width: '90%' }} alignItems={'flex-start'} paddingY={5} spacing={3}>
                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                        <Avatar alt="Profile" sx={{ width: 40, height: 40 }} src={ProfileRedux.photo_url || Assets.image.avatar} />
                        <Typography variant="body1" color={Colors.MAIN} fontWeight={'300'}>{ProfileRedux.first_name + ' ' + ProfileRedux.last_name}</Typography>
                    </Stack>
                    <div style={{ ...CENTER, width: '100%' }}>
                        <Stack paddingY={2} paddingX={5} direction={'column'} alignItems={'center'} justifyContent={'center'} spacing={0.5} sx={{ backgroundColor: '#ffffff30', borderRadius: 100, width: 'auto' }}>
                            <Typography variant="body2" color="#fff" fontWeight={'300'}>Your Points:</Typography>
                            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                <img src={Assets.icon.drxLogo} style={{ width: 40, height: 'auto', aspectRatio: 1, objectFit: 'contain' }} alt="" />
                                <Typography variant="h6" color={Colors.MAIN} fontWeight={'600'}>
                                    <CountUp end={TotalCoins} />
                                </Typography>
                            </Stack>
                        </Stack>
                    </div>
                    <Stack
                        direction={'column'}
                        sx={{
                            backgroundImage: `url(${Assets.image.background4})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            borderRadius: 5,
                            transition: 'all .3s'
                        }}
                        width={'100%'}
                        alignItems={'center'}
                    >
                        <Stack
                            direction={'column'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            spacing={1}
                            width={'90%'}
                            paddingY={3}
                        >
                            <Typography variant="body1" color="#fff">Total Tap</Typography>
                            <Stack
                                direction={'row'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                spacing={1}
                                sx={{ border: '1px solid #fff', borderRadius: 50, padding: '10px 20px', userSelect: 'none', backdropFilter: 'blur(3px)' }}
                            >
                                <img src={Assets.icon.drxLogo} style={{ width: 25, height: 'auto', aspectRatio: 1, objectFit: 'contain' }} alt="" />
                                <Typography variant="body2" color="#fff">+{TapCount}</Typography>
                            </Stack>
                        </Stack>
                        <div style={{ width: '100%', height: '40vh', borderRadius: 'inherit', userSelect: 'none' }}>
                            <div style={{ width: '100%', height: '100%', ...CENTER }}>
                                <div style={{ position: "relative", display: "inline-block", width: '100%', height: '100%' }} ref={particleClicksRef}>
                                    <motion.button
                                        // ref={buttonRef}
                                        onClick={handleClick}
                                        whileTap={{ scale: 0.85 }}
                                        // onTouchStart={handleClick}
                                        style={{ backgroundColor: 'transparent', width: '100%', border: 'none', height: '100%' }}
                                    >
                                        <img
                                            src={Assets.icon.drxLogo}
                                            style={{
                                                width: '50%',
                                                height: 'auto',
                                                aspectRatio: 1,
                                                objectFit: 'contain',
                                                userSelect: 'none',
                                                pointerEvents: 'none'
                                            }}
                                            alt="click-logo"
                                        />
                                    </motion.button>
                                </div>
                            </div>
                        </div>
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} width={'90%'} paddingY={3}>
                            <div ref={scope} style={{ position: 'relative' }}>
                                <Stack
                                    onClick={onClaim}
                                    className="claim-btn"
                                    direction={'row'}
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                    spacing={1}
                                    sx={{
                                        cursor: 'pointer',
                                        userSelect: 'none',
                                        backdropFilter: 'blur(3px)'
                                    }}
                                >
                                    <Icon fontSize={'large'} sx={{ color: Colors.MAIN }}>keyboard_double_arrow_up</Icon>
                                    <Typography className="sr-only" variant="h6" color={Colors.MAIN}>Claim!</Typography>
                                    <Typography className="letters-container" aria-hidden variant="h6" color={Colors.MAIN}>
                                        {["C", "l", "a", "i", "m", "!"].map((letter, index) => (
                                            <span data-letter={letter} className="letter" key={`${letter}-${index}`}>{letter}</span>
                                        ))}
                                    </Typography>
                                </Stack>
                                <span aria-hidden className="sparkles">
                                    {Array.from({ length: 20 }).map((_, index) => (
                                        <img
                                            key={`sparkle-${index}`}
                                            className={`sparkle sparkle-${index}`}
                                            src={Assets.icon.drxLogo}
                                            style={{ width: 15, height: 'auto', aspectRatio: 1, objectFit: 'contain' }}
                                            alt=""
                                        />
                                    ))}
                                </span>
                            </div>
                        </Stack>
                    </Stack>
                    <div style={{ width: '25%', height: 'auto', aspectRatio: 4 / 3 }}></div>
                </Stack>
                <BottomNavigation activePage={'Home'} navigation={navigate} />
            </div>
        </div >
    )
}

export default RouterTransitions(HomePage);