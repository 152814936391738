import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import { CENTER } from '../../utils/utilities';

export const Slider = (props: { data: any }) => {
    return (
        <Swiper
            slidesPerView={1.8}
            centeredSlides={true}
            spaceBetween={10}
            loop={true}
            autoplay={{
                delay: 2000,
                disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            style={{ width: '100%', height: '100%' }}
        >
            {
                props.data.map((item: any, index: number) => (
                    <SwiperSlide key={index}>
                        <div style={{ width: '100%', height: '50vh', borderRadius: 20, backgroundColor: '#ffffff50', ...CENTER }}>
                            <img src={item} style={{ width: '100%', height: '100%', objectFit: 'fill', borderRadius: 20, userSelect: 'none' }}></img>
                        </div>
                    </SwiperSlide>
                ))
            }
        </Swiper>
    )
}