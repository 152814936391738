import { Box, Icon, LinearProgress, Stack, Typography } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router';
import { Assets } from '../../assets';
import { Slider } from '../../components/startup/slider';
import RouterTransitions from '../../routes/routerTransition';
import { CENTER, Colors } from '../../utils/utilities';
import './styles.css'
import { useInitData, type User } from '@telegram-apps/sdk-react';
import { useDispatch, useSelector } from 'react-redux';
import { setProfileData } from '../../stores/modules/profile';
import { RootState } from '../../stores/rootReducer';
import { HTTPUserCheck, HTTPUserCheckReferral, HTTPUserCreate, HTTPUserGet, HTTPUserPicture } from '../../servers/users';
import { useSearchParams } from 'react-router-dom';

function getUserRows(user: User): any {
    return {
        id: user.id.toString() || '',
        username: user.username || '',
        last_name: user.lastName || '',
        first_name: user.firstName || '',
    };
}

const Sliders = [
    Assets.image.slide1,
    Assets.image.slide2,
    Assets.image.slide3,
    Assets.image.slide1,
    Assets.image.slide2,
    Assets.image.slide3,
    Assets.image.slide1,
    Assets.image.slide2,
    Assets.image.slide3,
]

const StartupPage = () => {
    const navigate = useNavigate()
    const initData = useInitData()
    const dispatch = useDispatch()
    // const [searchParams, setSearchParams] = useSearchParams();
    const [init, setInit] = React.useState(false)
    const [isReadyApp, setReadyApp] = React.useState(false)
    const ProfileRedux = useSelector((state: RootState) => state.profile_data.data)

    const userRows = React.useMemo<any>(() => {
        return initData && initData.user ? getUserRows(initData.user) : undefined;
    }, [initData]);

    const GetUser = async () => {
        try {
            await HTTPUserGet({ user_id: userRows.id })
        } catch (error) {
            console.log(error)
        }
    }

    const CreateUser = async () => {
        try {
            const picture = await HTTPUserPicture({ user_id: userRows.id })
            await HTTPUserCreate({
                user_id: userRows.id,
                first_name: userRows.first_name,
                last_name: userRows.last_name,
                photo_url: picture,
                username: userRows.username
            })
        } catch (error) {
            console.log(error)
        }
    }

    const CheckUser = async () => {
        try {
            const result = await HTTPUserCheck({ user_id: userRows.id })
            if (result.data.data === "0") {
                await CreateUser()
            } else {
                await GetUser()
            }
        } catch (error) {
            console.log(error)
        }
    }

    const StartApp = async () => {
        try {
            const Params: any = window.Telegram.WebApp.initDataUnsafe
            setReadyApp(false)
            const picture = await HTTPUserPicture({ user_id: userRows.id })
            dispatch(setProfileData({
                data: {
                    first_name: userRows.first_name,
                    id: userRows.id,
                    last_name: userRows.last_name,
                    photo_url: picture,
                    username: userRows.username,
                }
            }))
            setReadyApp(true)
            await CheckUser()
            if (Params.start_param !== undefined) {
                await HTTPUserCheckReferral({ referral: Params.start_param as string })
            }
        } catch (error) {
            console.log(error)
        }
    }

    React.useEffect(() => {
        async function Initial() {
            await StartApp()
        }

        Initial()
    }, [init])

    return (
        <div style={{ ...CENTER, width: '100%' }}>
            {
                isReadyApp === true ?
                    <div style={{ width: '100%', maxWidth: '768px', backgroundColor: '#000' }}>
                        <Stack sx={{ width: '100%' }} alignItems={'center'}>
                            <Stack direction={'column'} spacing={2} width={'90%'} paddingY={3} alignItems={'flex-start'}>
                                <Stack direction={'row'} alignItems={'center'} width={"100%"} justifyContent={'space-between'}>
                                    <Stack direction={'row'} alignItems={'center'} spacing={1} paddingY={1} paddingX={2} borderRadius={100} sx={{ backgroundColor: '#ffffff30' }}>
                                        <img src={Assets.icon.drxLogo} style={{ width: 30, height: 'auto', aspectRatio: 1, objectFit: 'contain' }} alt="" />
                                        <Typography variant="body2" color="#fff" fontWeight={'600'}>DRX Token</Typography>
                                    </Stack>
                                    <Typography variant="body2" color="#ffffff50">Alpha 14.092024</Typography>
                                </Stack>
                                <Stack
                                    direction={'row'}
                                    justifyContent={"center"}
                                    alignItems={'center'}
                                    spacing={1}
                                    paddingY={1}
                                    borderRadius={100}
                                    sx={{ backgroundColor: '#ffffff30', width: '100%' }}
                                >
                                    <Typography variant="body2" color="#fff" fontWeight={'400'}>Hello,</Typography>
                                    <img src={ProfileRedux.photo_url || Assets.image.avatar} style={{ width: 30, height: 'auto', aspectRatio: 1, objectFit: 'contain', borderRadius: 50 }} alt="" />
                                    <Typography variant="body2" color={Colors.MAIN} fontWeight={'600'}>{ProfileRedux.first_name}</Typography>
                                </Stack>
                                <Typography variant="h5" color="#fff" fontWeight={'600'} width={'70%'}>Discover, and collect Our DRX Token</Typography>
                                <Typography variant="body1" color="#C7C7C7" fontWeight={'300'} width={'70%'}>Choose DRX for the ultimate blend of tech, fashion, and decentralized connectivity.</Typography>
                            </Stack>
                            <Slider data={Sliders} />
                            <br />
                            <div style={{ width: '50%', height: 'auto', aspectRatio: 4 / 3 }}></div>
                        </Stack>
                        <div style={{ ...CENTER, width: '100%', position: 'fixed', bottom: '5%', left: 0, right: 0, zIndex: 1 }}>
                            <div
                                onClick={() => navigate('/home')}
                                className='pulse'
                                style={{
                                    ...CENTER,
                                    width: '80%',
                                    padding: '20px 0',
                                    cursor: 'pointer',
                                    gap: 10
                                }}
                            >
                                <Typography variant="body1" color="#fff"><b>Get Started</b></Typography>
                                <Icon fontSize={"medium"} sx={{ color: '#fff' }}>keyboard_double_arrow_right</Icon>
                            </div>
                        </div>
                    </div>
                    :
                    <div style={{ height: '100vh', width: '100vh', ...CENTER }}>
                        <Stack direction={'column'} width={'80%'} alignItems={'center'} justifyContent={'center'} spacing={3}>
                            <Typography variant="h5" color="#fff">Loading...</Typography>
                            <Box color={Colors.MAIN} width={'80%'}>
                                <LinearProgress color={'inherit'} />
                            </Box>
                        </Stack>
                    </div>
            }
        </div>
    )
}

export default RouterTransitions(StartupPage);